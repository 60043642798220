<template>
    <el-container class="app">
        <el-aside :width="isCollapse ? 'auto' : '247px'" class="left-box">
            <div class="flag">{{ isCollapse ? '创' : '创峰达人联盟' }}</div>
            <div class="nav-box">
                <el-menu default-active="/" class="el-menu-vertical" :router="true" :unique-opened="true"
                         @open="handleOpen" @close="handleClose" :collapse="isCollapse" background-color="#303133"
                         text-color="hsla(0,0%,100%,.7)"
                         active-text-color="#ffffff">
                    <el-menu-item index="/main">
                        <i class="el-icon-s-platform"></i>
                        <span slot="title">首页</span>
                    </el-menu-item>
                    <template v-for="item of menus">
                        <el-submenu v-if="item.children" :key="item.id" :index="item.path">
                            <template slot="title">
                                <i :class="item.icon"></i>
                                <span>{{ item.name }}</span>
                            </template>
                            <el-menu-item v-for="child of item.children" :key="child.id" :index="child.path">
                                {{ child.name }}
                            </el-menu-item>
                        </el-submenu>
                        <el-menu-item v-else :key="item.id" :index="item.path">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </el-menu>
            </div>
        </el-aside>
        <el-container>
            <el-header class="top-box">
                <div>
                    <i @click="isCollapse = !isCollapse" :class="[isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']"
                       class="pointer font-size-18"></i>
                </div>
                <div class="top-box__right">
                    <el-dropdown @command="doAction">
                        <span class="el-dropdown-link">
                            <img class="avatar" :src="($store.state.user && $store.state.user.avatar) ? $store.state.user.avatar : '../assets/logo.png'" alt="">&nbsp;&nbsp;<i
                            class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="info">个人信息</el-dropdown-item>
                            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
            <el-main class="main">
                <router-tab/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
    name: "Main",
    data: function () {
        return {
            isCollapse: false,
            menus: [],
            user: null
        }
    },
    created: function () {
        this.getSystemInfo()
        this.getUserInfo()
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        getUserInfo: function () {
            this.$helper._get(this, 'admin.info', {}, resp => {
                this.$store.commit('updateUser', resp.data)
            })
        },
        getSystemInfo: function () {
            this.$helper._get(this, 'admin.menu.info', {}, resp => {
                console.log(resp.data)
                this.menus = resp.data.menus
                this.$helper.setSession('menus', resp.data.routers)
                const routers = []
                for (const router of resp.data.routers) {
                    routers.push({
                        name: router.path,
                        path: router.path,
                        component: () => import(`../views${router.path}.vue`),
                        meta: {
                            title: router.name,
                        }
                    })
                }
                this.$router.addRoutes([{
                    path: '/main',
                    name: 'main',
                    component: () => import(`../views/Main.vue`),
                    children: routers
                }])
                this.$store.commit('updatePermissions', resp.data.actions)
            })
        },
        doAction: function (cmd) {
            switch (cmd) {
                case 'info':
                    this.$router.push('/info')
                    break
                case 'logout':
                    this.logout()
                    break
            }
        },
        logout: function () {
            this.$confirm('是否确认退出系统？', '提示').then(() => {
                this.$helper.removeSession('__TOKEN__')
                this.$helper.removeSession('menus')
                this.$router.replace("/", () => {
                });
            }).catch(() => {
            });
        }
    }
}
</script>

<style scoped lang="scss">
.left-box {
    height: 100vh;
    overflow: hidden;
    background: #303133;
    display: flex;
    flex-direction: column;

    .nav-box {
        flex: 1;
        height: 100%;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            background: #585B62;
        }

        &:hover {
            overflow-y: auto;
        }
    }

    .flag {
        color: hsla(0, 0%, 100%, .7);
        text-align: center;
        font-size: 24px;
        padding-top: 25px;
        padding-bottom: 5px;
    }
}

.el-menu-vertical:not(.el-menu--collapse) {
    width: 240px;
}

.top-box {
    border-bottom: 1px solid #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-box__right {
        display: flex;
        align-items: center;

        .avatar {
            width: 24px;
            height: 24px;
            border: 2px solid #f0f2f5;
            border-radius: 50%;
        }

        .el-dropdown-link {
            display: flex;
            align-items: center;
        }
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
}
</style>
